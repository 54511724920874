<template>
  <mobile-navigation :items="topItems" />
  <desktop-navigation :items="topItems" />
  <div class="lg:pl-72">
    <app-bar />

    <main class="py-10">
      <div class="px-4 sm:px-6 lg:px-8">
        <slot />
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import AppBar from "~/components/shell/AppBar.vue";
import MobileNavigation from "~/components/shell/MobileNavigation.vue";
import DesktopNavigation from "~/components/shell/DesktopNavigation.vue";

import { useMainStore } from "~/store/index";

import { Home, MailCheck, FolderLock, ShieldQuestion, Presentation, CheckCheck, ListTodo } from "lucide-vue-next";

const store = useMainStore();
const user = useStrapiUser();
const strapi = useStrapi();

onBeforeMount(async () => {
  await store.initialize();
});

const topItems = ref([
  {
    icon: Home,
    title: "Domovská stránka",
    to: "/",
  },
  {
    icon: MailCheck,
    title: "Žádosti",
    to: "/requests",
  },
  {
    icon: FolderLock,
    title: "Dokumenty",
    to: "/files",
  },
  {
    icon: ShieldQuestion,
    title: "Časté dotazy",
    to: "/faq",
  },
  {
    icon: Presentation,
    title: "Školení",
    to: "/courses",
  },
  {
    icon: CheckCheck,
    title: "Vaše povinnosti",
    to: "/responsibilities",
  },
  {
    icon: ListTodo,
    title: "Analýza",
    to: "/analysis",
  },
]);

</script>
