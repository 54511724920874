<script setup lang="ts">
import { Button } from "@/components/ui/button";
import { ChevronDown } from "lucide-vue-next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
const { locale, locales, setLocale } = useI18n();

const languages = [
  { name: "Čeština", code: "cs", flag: "🇨🇿" },
  //TODO: enable
  //{ name: "Slovenčina", code: "sk", flag: "🇸🇰" },
];

const selectedLanguage = ref(
  languages.find((lang) => lang.code === locale.value)
);

function changeLanguage(lang) {
  setLocale(lang.code);
  locale.value = lang.code;
  selectedLanguage.value = lang;
}
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger>
      <Button variant="ghost">
        <span class="hidden lg:flex lg:items-center font-large text-lg">
          {{ selectedLanguage.flag }}
          <ChevronDown />
        </span>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent>
      <DropdownMenuItem
        v-for="lang in languages"
        :key="lang.code"
        @click="changeLanguage(lang)"
      >
        {{ lang.flag }}
        {{ lang.name }}
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
