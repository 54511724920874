<script setup lang="ts">
import { useMainStore } from "~/store";
import { useRoute } from "vue-router";

const route = useRoute();
const store = useMainStore();

defineProps<{
  items: { title: string; to: string }[];
}>();
const showNavigation = computed(() => store.showMobileNavigation);
</script>
<template>
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div
    class="relative z-50 lg:hidden"
    :class="{ hidden: !showNavigation }"
    role="dialog"
    aria-modal="true"
  >
    <!--
      Off-canvas menu backdrop, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-900/80"></div>

    <div class="fixed inset-0 flex">
      <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
      <div class="relative mr-16 flex w-full max-w-xs flex-1">
        <!--
          Close button, show/hide based on off-canvas menu state.

          Entering: "ease-in-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in-out duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
          <button
            type="button"
            class="-m-2.5 p-2.5"
            @click="store.showMobileNavigation = !store.showMobileNavigation"
          >
            <span class="sr-only">Zavřít</span>
            <svg
              class="h-6 w-6 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div
          class="flex grow flex-col gap-y-5 overflow-y-auto bg-current px-6 pb-4"
        >
          <div class="flex h-16 shrink-0 items-center">
            <img class="h-8 w-auto" src="/logo-short.svg" alt="Váš Pověřenec" />
            <div>
              <h1 class="ml-2 text-lg font-bold text-white">Váš Pověřenec</h1>
            </div>
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul
                  role="list"
                  class="-mx-2 my-1 space-y-1"
                  v-for="item in items"
                  :key="item"
                >
                  <li>
                    <NuxtLink
                      v-if="!item.subItems"
                      :to="item.to"
                      class="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      @click="store.showMobileNavigation = false"
                      :class="
                        route.path === item.to
                          ? 'bg-primary text-white'
                          : 'text-white hover:bg-primary/20'
                      "
                    >
                      <component
                        class="h-6 w-6 shrink-0"
                        :is="item.icon"
                      ></component>
                      {{ item.title }}
                    </NuxtLink>
                    <div v-else>
                      <button
                        type="button"
                        class="group flex items-center w-full text-left gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        :class="
                          route.path === item.to
                            ? 'bg-primary text-white'
                            : 'text-white hover:bg-primary/20'
                        "
                        aria-controls="sub-menu-1"
                        aria-expanded="false"
                      >
                        <component
                          class="h-6 w-6 shrink-0"
                          :is="item.icon"
                        ></component>
                        {{ item.title }}
                        <!-- Expanded: "rotate-90 text-gray-500", Collapsed: "text-gray-400" -->
                        <svg
                          class="text-gray-400 ml-auto h-5 w-5 shrink-0"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                      <!-- Expandable link section, show/hide based on state. -->
                      <ul class="mt-1 px-2" id="sub-menu-1">
                        <li v-for="subItem in item.subItems" :key="subItem">
                          <NuxtLink
                            :to="subItem.to"
                            class="group flex items-center w-full text-left gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            :class="
                              route.path === item.to
                                ? 'bg-primary text-white'
                                : 'text-white hover:bg-primary/20'
                            "
                          >
                            {{ subItem.title }}</NuxtLink
                          >
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="mt-auto">
                <NuxtLink
                  to="/settings"
                  class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
                >
                  <svg
                    class="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  Nastavení
                </NuxtLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
