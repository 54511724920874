<script setup lang="ts">
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { ChevronDown } from "lucide-vue-next";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

import { useMainStore } from "~/store/index";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useMainStore();
const { logout } = useStrapiAuth();
const user = useStrapiUser();

const breadcrumbs = computed(() => {
  return store.breadcrumbs;
});

function performLogout() {
  // Ensure chat is not being refreshed
  clearInterval(store.intervalId);
  logout();
  router.push("/auth/signin");
}
</script>

<template>
  <div
    class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6"
  >
    <button
      type="button"
      class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
      @click="store.showMobileNavigation = !store.showMobileNavigation"
    >
      <span class="sr-only">Otevřít navigaci</span>
      <svg
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        />
      </svg>
    </button>

    <!-- Separator -->
    <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"></div>

    <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
      <div class="relative flex flex-1">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-2">
          <li class="flex">
            <div class="flex items-center">
              <NuxtLink to="/" class="text-gray-400 hover:text-gray-500">
                <svg
                  class="h-5 w-5 flex-shrink-0"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="sr-only">{{ $t("navigation.home") }}</span>
              </NuxtLink>
            </div>
          </li>
          <li v-for="item in breadcrumbs" :key="item" class="flex">
            <div class="flex items-center">
              <svg
                class="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <NuxtLink
                :to="item.to"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current="page"
                >{{ item.title }}</NuxtLink
              >
            </div>
          </li>
        </ol>
      </div>
      <div class="flex items-center gap-x-4 lg:gap-x-6">
        <LanguageSwitcher />
        <div
          class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
          aria-hidden="true"
        ></div>

        <div class="relative">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <button
                type="button"
                class="-m-1.5 flex items-center p-1.5"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span class="sr-only">Otevřít menu</span>
                <Avatar>
                  <AvatarFallback
                    >{{ user?.firstName?.[0].toUpperCase() }}
                    {{ user?.lastName?.[0].toUpperCase() }}</AvatarFallback
                  >
                </Avatar>
                <span class="hidden lg:flex lg:items-center">
                  <span
                    class="ml-4 text-sm font-semibold leading-6 text-gray-900"
                    aria-hidden="true"
                    >{{ user.firstName }} {{ user.lastName }}</span
                  >
                  <ChevronDown />
                </span>
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem disabled>
              {{ user.email }}
              </DropdownMenuItem>
              <DropdownMenuItem>
                <NuxtLink class="w-full" to="/settings">{{
                  $t("navigation.settings")
                }}</NuxtLink>
              </DropdownMenuItem>
              <DropdownMenuItem class="w-full" v-if="user.role.type === 'admin'">
                <NuxtLink to="/access">{{ $t("navigation.admin") }}</NuxtLink>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <button class="w-full text-left" @click="performLogout">{{
                  $t("actions.logout")
                }}</button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  </div>
</template>
